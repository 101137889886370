const SCENE_CONFIG = {
  backgroundColor: 0x201919,
  antialias: false,
  fxaaPass: false,
  gammaCorrectionPass: false,
  maxPixelRatio: 2,
  isMobile: false,
};

export default SCENE_CONFIG;
