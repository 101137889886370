import Chair from "../room-active-objects/chair/chair";
import ChairDebugMenu from "../room-active-objects/chair/chair-debug-menu";
import Keyboard from "../room-active-objects/keyboard/keyboard";
import KeyboardDebugMenu from "../room-active-objects/keyboard/keyboard-debug-menu";
import Monitor from "../room-active-objects/monitor/monitor";
import MonitorDebugMenu from "../room-active-objects/monitor/monitor-debug-menu";
import Mouse from "../room-active-objects/mouse/mouse";
import MouseDebugMenu from "../room-active-objects/mouse/mouse-debug-menu";
import Table from "../room-active-objects/table/table";
import TableDebugMenu from "../room-active-objects/table/table-debug-menu";
import { ROOM_OBJECT_TYPE } from "./room-config";
import MousePad from "../room-inactive-objects/objects/mouse-pad/mouse-pad";

const ROOM_OBJECT_CLASS = {
  // Active objects
  [ROOM_OBJECT_TYPE.Chair]: {
    object: Chair,
    debugMenu: ChairDebugMenu,
  },
  [ROOM_OBJECT_TYPE.Table]: {
    object: Table,
    debugMenu: TableDebugMenu,
  },
  [ROOM_OBJECT_TYPE.Monitor]: {
    object: Monitor,
    debugMenu: MonitorDebugMenu,
  },
  [ROOM_OBJECT_TYPE.Keyboard]: {
    object: Keyboard,
    debugMenu: KeyboardDebugMenu,
  },
  [ROOM_OBJECT_TYPE.Mouse]: {
    object: Mouse,
    debugMenu: MouseDebugMenu,
  },

  // Inactive objects
  [ROOM_OBJECT_TYPE.MousePad]: {
    object: MousePad,
  },
}

export { ROOM_OBJECT_CLASS };
