import { MessageDispatcher } from 'black-engine';

export default class MonitorScreenScene {
  constructor(data) {

    this.events = new MessageDispatcher();

    this._scene = data.scene;
    this._camera = data.camera;
    this._audioListener = data.audioListener;

    this._initGame();
  }

  onKeyPressed() {
  }

  startGame() {
  }

  stopGame() {
  }

  update() {
  }

  getSoundsAnalyzer() {
  }

  onSoundsEnabledChanged() {
  }

  onVolumeChanged() {
  }

  _initGame() {

  }
}
