import { TABLE_PART_ACTIVITY_CONFIG, TABLE_PART_TYPE } from '../room-active-objects/table/data/table-data';
import { CHAIR_PART_ACTIVITY_CONFIG, CHAIR_PART_TYPE } from '../room-active-objects/chair/data/chair-data';
import { MOUSE_PART_ACTIVITY_CONFIG, MOUSE_PART_TYPE } from '../room-active-objects/mouse/data/mouse-data';
import { KEYBOARD_PART_ACTIVITY_CONFIG, KEYBOARD_PART_TYPE } from '../room-active-objects/keyboard/data/keyboard-data';
import { MONITOR_PART_ACTIVITY_CONFIG, MONITOR_PART_TYPE } from '../room-active-objects/monitor/data/monitor-data';

const ROOM_CONFIG = {
  autoOpenActiveDebugFolder: true,
  clickActiveObjectError: 2, // pixels
}

const ROOM_OBJECT_TYPE = {
  // Active objects
  Table: 'TABLE',
  Monitor: 'MONITOR',
  Keyboard: 'KEYBOARD',
  Mouse: 'MOUSE',
  Chair: 'CHAIR',

  // Inactive objects
  MousePad: 'MOUSE_PAD',

  // Other
  Global: 'GLOBAL',
}

const ROOM_OBJECT_ACTIVITY_TYPE = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
}

const ROOM_OBJECT_CONFIG = {
  // Active objects
  [ROOM_OBJECT_TYPE.Table]: {
    createObject: true,
    label: 'Table',
    debugFolderLabel: 'Table',
    activityType: ROOM_OBJECT_ACTIVITY_TYPE.Active,
    groupName: 'Table',
    partTypes: TABLE_PART_TYPE,
    partsActiveConfig: TABLE_PART_ACTIVITY_CONFIG,
  },
  [ROOM_OBJECT_TYPE.Chair]: {
    createObject: true,
    label: 'Chair',
    debugFolderLabel: 'Chair',
    activityType: ROOM_OBJECT_ACTIVITY_TYPE.Active,
    groupName: 'Chair',
    partTypes: CHAIR_PART_TYPE,
    partsActiveConfig: CHAIR_PART_ACTIVITY_CONFIG,
    isDraggable: true,
  },
  [ROOM_OBJECT_TYPE.Mouse]: {
    createObject: true,
    label: 'Mouse',
    debugFolderLabel: 'Mouse',
    activityType: ROOM_OBJECT_ACTIVITY_TYPE.Active,
    groupName: 'Mouse',
    tableGroup: true,
    partTypes: MOUSE_PART_TYPE,
    partsActiveConfig: MOUSE_PART_ACTIVITY_CONFIG,
    isDraggable: true,
  },
  [ROOM_OBJECT_TYPE.Keyboard]: {
    createObject: true,
    label: 'Keyboard',
    debugFolderLabel: 'Keyboard',
    activityType: ROOM_OBJECT_ACTIVITY_TYPE.Active,
    groupName: 'Keyboard',
    tableGroup: true,
    partTypes: KEYBOARD_PART_TYPE,
    partsActiveConfig: KEYBOARD_PART_ACTIVITY_CONFIG,
  },
  [ROOM_OBJECT_TYPE.Monitor]: {
    createObject: true,
    label: 'Monitor',
    debugFolderLabel: 'Monitor',
    activityType: ROOM_OBJECT_ACTIVITY_TYPE.Active,
    groupName: 'Monitor',
    tableGroup: true,
    partTypes: MONITOR_PART_TYPE,
    partsActiveConfig: MONITOR_PART_ACTIVITY_CONFIG,
    isDraggable: true,
  },

  // Inactive objects
  [ROOM_OBJECT_TYPE.MousePad]: {
    createObject: true,
    label: 'Mouse pad',
    activityType: ROOM_OBJECT_ACTIVITY_TYPE.Inactive,
    meshName: 'Mouse_pad',
    tableGroup: true,
  },
}

const SCALE_ZERO = 0.01;

const MONITOR_TYPE = {
  Monitor: 'MONITOR',
}

export {
  ROOM_CONFIG,
  ROOM_OBJECT_TYPE,
  ROOM_OBJECT_CONFIG,
  ROOM_OBJECT_ACTIVITY_TYPE,
  MONITOR_TYPE,
  SCALE_ZERO,
};
