import * as THREE from "three";
import RoomInactiveObjectAbstract from "../../room-inactive-object-abstract";

export default class MousePad extends RoomInactiveObjectAbstract {
  constructor(roomScene, roomObjectType) {
    super(roomScene, roomObjectType);

    this._secretTextureTween = null;
    this._isSecretTextureActive = false;

    this._addMaterials();
  }

  onLightPercentChange(lightPercent) {
    this._mesh.material.uniforms.uMixPercent.value = lightPercent;
  }

  _addMaterials() {
    const material = new THREE.MeshStandardMaterial({ color: 0x171415 });
    this._mesh.material = material;
  }
}
