import { ROOM_OBJECT_TYPE } from "./room-config";

const SOUNDS_CONFIG = {
  enabled: true,
  volume: 0.5,
  debugHelpers: false,
  closedWindowOuterGain: 0.1,
  openedWindowOuterGain: 0.6,
  objects: {
    [ROOM_OBJECT_TYPE.Keyboard]: {
      volume: 1,
      refDistance: 10,
      helperSize: 0.15,
    },
    [ROOM_OBJECT_TYPE.Mouse]: {
      volume: 1,
      refDistance: 10,
      helperSize: 0.15,
    },
    [ROOM_OBJECT_TYPE.Table]: {
      volume: 1,
      refDistance: 10,
      helperSize: 0.2,
    },
    [ROOM_OBJECT_TYPE.Chair]: {
      volume: 1,
      refDistance: 10,
      helperSize: 0.4,
    },
  }
}

export { SOUNDS_CONFIG };
