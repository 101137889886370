import * as THREE from 'three';
import { TWEEN } from '/node_modules/three/examples/jsm/libs/tween.module.min.js';
import { ROOM_CONFIG, ROOM_OBJECT_CONFIG, ROOM_OBJECT_TYPE } from './data/room-config';
import { Black, MessageDispatcher } from 'black-engine';
import { ROOM_OBJECT_ENABLED_CONFIG } from './data/room-objects-enabled-config';
import { arraysEqual } from './shared/helpers';
import { SOUNDS_CONFIG } from './data/sounds-config';
import { CAMERA_FOCUS_OBJECT_TYPE, CAMERA_MODE } from './camera-controller/data/camera-data';
import { CAMERA_CONFIG } from './camera-controller/data/camera-config';
import { GLOBAL_ROOM_OBJECT_ENABLED_CONFIG } from './data/global-room-objects-enabled-config';
import { THEATRE_JS_CONFIG } from './intro/theatre-js/data/theatre-js-config';
import { INTRO_CONFIG } from './intro/intro-config';
import DEBUG_CONFIG from '../../core/configs/debug-config';

export default class RoomController {
  constructor(data) {
    this.events = new MessageDispatcher();

    this._scene = data.scene;
    this._camera = data.camera;
    this._renderer = data.renderer;
    this._orbitControls = data.orbitControls;
    this._audioListener = data.audioListener,
    this._cameraController = data.cameraController;
    this._raycasterController = data.raycasterController;
    this._lightsController = data.lightsController;
    this._intro = data.intro;

    this._roomScene = data.roomScene;
    this._roomDebug = data.roomDebug;
    this._cursor = data.cursor;

    this._roomActiveObject = data.roomActiveObject;
    this._roomInactiveObject = data.roomInactiveObject;
    this._roomObjectsByActivityType = data.roomObjectsByActivityType;

    this._pointerPositionOnDown = new THREE.Vector2();
    this._previousPointerPosition = new THREE.Vector2();
    this._pointerPosition = new THREE.Vector2();
    this._draggingObject = null;

    this._previousZoomInPosition = new THREE.Vector2();
    this._currentZoomInPosition = new THREE.Vector2();

    this._glowMeshesNames = [];
    this._previousGlowMeshesNames = [];

    this._isGameActive = false;
    this._isReserveCameraActive = false;
    this._isActiveObjectsHighlighted = false;
    this._isObjectsMoving = false;

    this._init();
  }

  update(dt) {
    if (dt > 0.1) {
      dt = 0.1;
    }

    const isPointerMoved = this._isObjectsMoving || (this._previousPointerPosition.x !== this._pointerPosition.x || this._previousPointerPosition.y !== this._pointerPosition.y) ;
    const intersect = this._raycasterController.checkIntersection(this._pointerPosition.x, this._pointerPosition.y);

    if (intersect === null) {
      Black.engine.containerElement.style.cursor = 'auto';
      this._glowMeshesNames = [];
      this._previousGlowMeshesNames = [];
    }

    if (intersect && intersect.object && !this._draggingObject && isPointerMoved) {
      this._checkToGlow(intersect);
    }

    if (intersect && intersect.object && intersect.object.userData.objectType === ROOM_OBJECT_TYPE.Global && intersect.object.userData.isActive) {
      if (Black.engine.containerElement.style.cursor !== 'zoom-out') {
        Black.engine.containerElement.style.cursor = 'zoom-out';
      }
    }

    if (!this._isActiveObjectsHighlighted && (intersect === null || intersect.instanceId !== undefined)) {
      this._resetGlow();
    }

    this._updateObjects(dt);
    this._previousPointerPosition.set(this._pointerPosition.x, this._pointerPosition.y);
  }

  onPointerMove(x, y) {
    this._pointerPosition.set(x, y);
    this._cameraController.onPointerMove(x, y);

    if (this._draggingObject) {
      const raycaster = this._raycasterController.getRaycaster();
      this._draggingObject.onPointerMove(raycaster);
    }
  }

  onPointerDown(x, y) {
    this._pointerPositionOnDown.set(x, y);
    const intersect = this._raycasterController.checkIntersection(x, y);

    if (!intersect) {
      return;
    }

    const intersectObject = intersect.object;

    if (intersectObject && intersectObject.userData.isActive
      && ROOM_OBJECT_ENABLED_CONFIG[intersect.object.userData.objectType] && GLOBAL_ROOM_OBJECT_ENABLED_CONFIG[intersect.object.userData.objectType]) {
      const objectType = intersect.object.userData.objectType;
      const objectConfig = ROOM_OBJECT_CONFIG[objectType];
      const roomObject = this._roomActiveObject[objectType];

      if (objectConfig.isDraggable) {
        const isObjectDraggable = roomObject.onClick(intersect, true);

        if (isObjectDraggable) {
          this._checkToShowDebugFolders(roomObject);

          this._draggingObject = roomObject;
          this._cameraController.onObjectDragStart();

          this._resetGlow();
          // this._setGlow(this._draggingObject.getMeshesForOutline(intersectObject));
        }
      }
    }

    this._cameraController.onPointerDown();
  }

  onPointerUp(x, y) {
    const isCursorMoved = Math.abs(Math.round(this._pointerPositionOnDown.x) - Math.round(x)) <= ROOM_CONFIG.clickActiveObjectError
      && Math.abs(Math.round(this._pointerPositionOnDown.y) - Math.round(y)) <= ROOM_CONFIG.clickActiveObjectError;

    if (this._draggingObject === null && isCursorMoved) {
      this._onPointerClick(x, y);
    }

    if (this._draggingObject) {
      this._draggingObject.onPointerUp();
      this._draggingObject = null;
      this._cameraController.onObjectDragEnd();
      this._previousGlowMeshesNames = [];
    }

    this._cameraController.onPointerUp();
  }

  onPointerLeave() {
    this._resetGlow();

    this._isActiveObjectsHighlighted = false;
    this._roomDebug.setActiveObjectsHighlightState(this._isActiveObjectsHighlighted);
  }

  onWheelScroll(delta) {
    this._cameraController.onWheelScroll(delta);
  }

  onSoundsEnabledChanged() {
    for (const key in this._roomActiveObject) {
      if (SOUNDS_CONFIG.enabled) {
        this._roomActiveObject[key].enableSound();
      } else {
        this._roomActiveObject[key].disableSound();
      }
    }
  }

  onIntroStart() {
    this._unblurScene();

    this._cameraController.stopMoveCameraToSides()
      .onComplete(() => this._intro.start());
  }

  onIntroSkip() {
    if (INTRO_CONFIG.active) {
      this._intro.stop();
    } else {
      this._cameraController.stopMoveCameraToSides()
        .onComplete(() => {
          this._cameraController.setOrbitState();
        });

      this._unblurScene();
      this._enableAllObjects();

      this._roomDebug.enableMonitorFocusButton();
      this._roomDebug.enableKeyboardFocusButton();
      this._roomDebug.enableStartCameraPositionButton();
      this._roomDebug.enableInteractWithAllObjectsButton();
    }
  }

  _blurScene() {
    this._renderer.domElement.style.filter = `blur(${INTRO_CONFIG.sceneBlur}px)`;
  }

  _unblurScene() {
    const blurObject = { value: INTRO_CONFIG.sceneBlur };

    new TWEEN.Tween(blurObject)
      .to({ value: 0 }, 500)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .start()
      .onUpdate(() => {
        this._renderer.domElement.style.filter = `blur(${blurObject.value}px)`;
      });
  }

  _onPointerClick(x, y) {
    const intersect = this._raycasterController.checkIntersection(x, y);

    if (!intersect) {
      return;
    }

    const intersectObject = intersect.object;

    if (intersectObject && intersectObject.userData.objectType === ROOM_OBJECT_TYPE.Global && intersectObject.userData.isActive) {
      if (intersectObject.userData.type === 'staticModeBackPlane') {
        this._cameraController.onStaticModeBackPlaneClick();
      }
    }

    if (intersectObject && intersectObject.userData.isActive
      && ROOM_OBJECT_ENABLED_CONFIG[intersect.object.userData.objectType] && GLOBAL_ROOM_OBJECT_ENABLED_CONFIG[intersect.object.userData.objectType]) {
      const objectType = intersect.object.userData.objectType;
      const roomObject = this._roomActiveObject[objectType];

      this._checkToShowDebugFolders(roomObject);
      roomObject.onClick(intersect, false);
    }
  }

  _updateObjects(dt) {
    for (const objectType in this._roomActiveObject) {
      this._roomActiveObject[objectType].update(dt);
    }

    for (const objectType in this._roomInactiveObject) {
      this._roomInactiveObject[objectType].update(dt);
    }

    this._cursor.update(dt);
    this._cameraController.update(dt);
    this._lightsController.update(dt);
    this._intro.update(dt);
  }

  _checkToGlow(intersect) {
    const object = intersect.object;

    const isObjectActive = object === null || !object.userData.isActive
      || (this._roomActiveObject[object.userData.objectType] && !this._roomActiveObject[object.userData.objectType].isInputEnabled())
      || !ROOM_OBJECT_ENABLED_CONFIG[object.userData.objectType]
      || !GLOBAL_ROOM_OBJECT_ENABLED_CONFIG[object.userData.objectType];

    if (isObjectActive) {
      if (!this._isActiveObjectsHighlighted) {
        this._resetGlow();
      }

      Black.engine.containerElement.style.cursor = 'auto';

      this._glowMeshesNames = [];
      this._previousGlowMeshesNames = [];

      return;
    }

    const roomObject = this._roomActiveObject[object.userData.objectType];
    const meshes = roomObject.getMeshesForOutline(object);
    
    if (!arraysEqual(this._glowMeshesNames, this._previousGlowMeshesNames)) {
      this._resetRoomObjectsPointerOver();

      if ((meshes[0] && meshes[0].userData.hideOutline === true)) {
        this._resetGlow();
      } else {
        this._setGlow(meshes);
        this._isActiveObjectsHighlighted = false;
        this._roomDebug.setActiveObjectsHighlightState(this._isActiveObjectsHighlighted);
      }

      roomObject.onPointerOver(intersect);
    }

    this._previousGlowMeshesNames = this._glowMeshesNames;
  }

  _setGlow() {

  }

  _resetGlow() {
  }

  _resetRoomObjectsPointerOver() {
    for (const key in this._roomActiveObject) {
      this._roomActiveObject[key].onPointerOut();
    }
  }

  _hideAllOtherObjectsDebugMenu(roomObject) {
    for (const key in this._roomActiveObject) {
      if (this._roomActiveObject[key] !== roomObject && this._roomActiveObject[key].hasDebugMenu()) {
        this._roomActiveObject[key].closeDebugMenu();
      }
    }
  }

  _checkToShowDebugFolders(roomObject) {
    if (ROOM_CONFIG.autoOpenActiveDebugFolder && roomObject.hasDebugMenu()) {
      this._hideAllOtherObjectsDebugMenu(roomObject);
      roomObject.openDebugMenu();
    }
  }

  _isInstancedObject(object) {
    return object instanceof THREE.InstancedMesh;
  }

  _init() {
    this._initSignals();

    if (SOUNDS_CONFIG.debugHelpers) {
      for (const key in this._roomActiveObject) {
        this._roomActiveObject[key].showSoundHelpers();
      }
    }

    if (THEATRE_JS_CONFIG.studioEnabled) {
      this._isReserveCameraActive = true;
      this._disableAllObjects();
    }

    this._onStart();
  }

  _onStart() {
    if (DEBUG_CONFIG.modeWithoutUI || DEBUG_CONFIG.skipIntro) {
      return;
    }

    this._blurScene();
    this._disableAllObjects();
    this._cameraController.setNoControlsState();
    this._cameraController.moveCameraToSides();

    this._roomDebug.disableMonitorFocusButton();
    this._roomDebug.disableKeyboardFocusButton();
    this._roomDebug.disableStartCameraPositionButton();

    this._roomDebug.disableInteractWithAllObjectsButton();
  }

  _initSignals() {
    this._initCursorSignals();
    this._initKeyboardSignals();
    this._initMonitorSignals();
    this._initDebugMenuSignals();
    this._initRealKeyboardSignals();
    this._initCameraControllerSignals();
    this._initIntroSignals();
    this._initOtherSignals();
  }

  _initCursorSignals() {
    const monitor = this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor];

    this._cursor.events.on('onMonitorButtonOver', (msg, buttonType) => monitor.onButtonOver(buttonType));
    this._cursor.events.on('onMonitorButtonOut', () => monitor.onButtonOut());
    this._cursor.events.on('onLeftKeyClick', (msg, buttonType, monitorType) => this._onMouseOnButtonClick(buttonType, monitorType));
  }

  _initKeyboardSignals() {
    const keyboard = this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard];

    keyboard.events.on('onKeyboardEscClick', () => this._onKeyboardEscClick());
    keyboard.events.on('onKeyboardSpaceClick', () => this._onKeyboardSpaceClick());
    keyboard.events.on('onKeyboardEnterClick', () => this._onKeyboardEnterClick());
    keyboard.events.on('onKeyboardMuteClick', () => this._onKeyboardMuteClick());
    keyboard.events.on('onKeyboardVolumeDownClick', () => this._onKeyboardVolumeDownClick());
    keyboard.events.on('onKeyboardVolumeUpClick', () => this._onKeyboardVolumeUpClick());
    keyboard.events.on('onKeyboardBaseClick', () => this._onKeyboardFocus());
    keyboard.events.on('onCloseFocusIconClick', () => this._onExitFocusMode());
    keyboard.events.on('onSecretCode', (msg, secretCodeType) => this._onSecretCode(secretCodeType));
  }

  _initMonitorSignals() {
    const monitor = this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor];

    monitor.events.on('onMonitorScreenClick', () => this._onMonitorFocus());
    monitor.events.on('onMonitorScreenClickForGame', () => this.events.post('onGameKeyPressed'));
    monitor.events.on('onCloseFocusIconClick', () => this._onExitFocusMode());
    monitor.events.on('onShowGame', () => this._onShowGame());
    monitor.events.on('onHideGame', () => this._onHideGame());
  }

  _initDebugMenuSignals() {
    this._roomDebug.events.on('fpsMeterChanged', () => this.events.post('fpsMeterChanged'));
    this._roomDebug.events.on('debugHelpersChanged', () => this._onDebugHelpersChanged());
    this._roomDebug.events.on('volumeChanged', () => this._onVolumeChanged());
    this._roomDebug.events.on('soundsEnabledChanged', () => this.onDebugSoundsEnabledChanged());
    this._roomDebug.events.on('onMonitorFocus', () => this._onMonitorFocus());
    this._roomDebug.events.on('onKeyboardFocus', () => this._onKeyboardFocus());
    this._roomDebug.events.on('onRoomFocus', () => this._onRoomFocus());
    this._roomDebug.events.on('onExitFocusMode', () => this._onExitFocusMode());
    this._roomDebug.events.on('onSwitchToReserveCamera', () => this._onSwitchToReserveCamera());
    this._roomDebug.events.on('highlightAllActiveObjects', () => this._highlightAllActiveObjects());
    this._roomDebug.events.on('allObjectsInteraction', () => this._allObjectsInteraction());
    this._roomDebug.events.on('onShowIntro', () => this._intro.switchIntro());
  }

  _initCameraControllerSignals() {
    this._cameraController.events.on('onObjectFocused', (msg, focusedObject) => this._onObjectFocused(focusedObject));
    this._cameraController.events.on('onRoomFocused', () => this._onRoomFocused());

  }

  _initIntroSignals() {
    this._intro.events.on('onResetActiveObjects', () => this._onResetActiveObjects());
    this._intro.events.on('onIntroStop', () => this._onIntroStop());
  }

  _initOtherSignals() {
    const mouse = this._roomActiveObject[ROOM_OBJECT_TYPE.Mouse];
    const table = this._roomActiveObject[ROOM_OBJECT_TYPE.Table];
    const chair = this._roomActiveObject[ROOM_OBJECT_TYPE.Chair];

    mouse.events.on('onCursorScaleChanged', () => this._cursor.onCursorScaleChanged());
    mouse.events.on('onLeftKeyClick', () => this._cursor.onMouseLeftKeyClicked());
    table.events.on('onTableMoving', () => this._onTableMoving());
    table.events.on('onTableMovingPercent', (msg, percent) => this._onTableMovingPercent(percent));
    table.events.on('onTableStop', (msg, tableState) => this._onTableStop(tableState));
    chair.events.on('onChairMoving', () => this._onObjectsMoving());
    chair.events.on('onChairStopMoving', () => this._onObjectsStopMoving());
    chair.events.on('onChairRotation', () => this._onObjectsMoving());
    chair.events.on('onChairStopRotation', () => this._onObjectsStopMoving());
  }

  _initRealKeyboardSignals() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        if (INTRO_CONFIG.active) {
          this.onIntroSkip();
        }
      }
    });
  }

  _onObjectsMoving() {
    this._isObjectsMoving = true;
  }

  _onObjectsStopMoving() {
    this._isObjectsMoving = false;
  }

  _onSongEnded() {
  }

  _onMonitorFocus() {
    const chair = this._roomActiveObject[ROOM_OBJECT_TYPE.Chair];
    chair.moveToStartPosition();

    this._disableScreensOnMonitorFocus();
    this._enableBaseOnKeyboardFocus();
    ROOM_OBJECT_ENABLED_CONFIG[ROOM_OBJECT_TYPE.Table] = false;
    this._roomActiveObject[ROOM_OBJECT_TYPE.Table].disableDebugMenu();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].hideCloseFocusIcon();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].enableRealKeyboard();
    this._cameraController.focusCamera(CAMERA_FOCUS_OBJECT_TYPE.Monitor);
  }

  _onKeyboardFocus() {
    this._disableBaseOnKeyboardFocus();
    this._enableScreensOnMonitorFocus();
    ROOM_OBJECT_ENABLED_CONFIG[ROOM_OBJECT_TYPE.Table] = false;
    this._roomActiveObject[ROOM_OBJECT_TYPE.Table].disableDebugMenu();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].hideCloseFocusIcon();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].enableRealKeyboard();
    this._cameraController.focusCamera(CAMERA_FOCUS_OBJECT_TYPE.Keyboard);
  }

  _onRoomFocus() {
    this._enableBaseOnKeyboardFocus();
    this._enableScreensOnMonitorFocus();
    ROOM_OBJECT_ENABLED_CONFIG[ROOM_OBJECT_TYPE.Table] = true;
    this._roomActiveObject[ROOM_OBJECT_TYPE.Table].enableDebugMenu();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].hideCloseFocusIcon();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].disableRealKeyboard();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].hideCloseFocusIcon();
    this._cameraController.focusCamera(CAMERA_FOCUS_OBJECT_TYPE.Room);
  }

  _onExitFocusMode() {
    this._enableBaseOnKeyboardFocus();
    this._enableScreensOnMonitorFocus();
    ROOM_OBJECT_ENABLED_CONFIG[ROOM_OBJECT_TYPE.Table] = true;
    this._roomActiveObject[ROOM_OBJECT_TYPE.Table].enableDebugMenu();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].hideCloseFocusIcon();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].disableRealKeyboard();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].hideCloseFocusIcon();
    this._cameraController.focusCamera(CAMERA_FOCUS_OBJECT_TYPE.LastPosition);
  }

  _onSwitchToReserveCamera() {
    if (this._isReserveCameraActive) {
      this._isReserveCameraActive = false;
      this._enableAllObjects();
    } else {
      this._isReserveCameraActive = true;
      this._disableAllObjects();
    }

    this.events.post('onSwitchToReserveCamera');
  }

  _disableFocusObjects() {
    this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].setScreenInactive();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].setBaseInactive();
    this._roomDebug.disableMonitorFocusButton();
    this._roomDebug.disableKeyboardFocusButton();
  }

  _enableFocusObjects() {
    this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].setScreenActive();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].setBaseActive();
    this._roomDebug.enableMonitorFocusButton();
    this._roomDebug.enableKeyboardFocusButton();
  }

  _onShowGame() {
    this._isGameActive = true;

    if (CAMERA_CONFIG.mode === CAMERA_MODE.Focused && CAMERA_CONFIG.focusObjectType === CAMERA_FOCUS_OBJECT_TYPE.Monitor) {
      this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].setScreenActive();
      this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].setScreenActiveForGame();
    }

    this._cursor.onFullScreenVideoStart();
    this.events.post('onShowGame')
  }

  _onHideGame() {
    this._isGameActive = false;

    if (CAMERA_CONFIG.mode === CAMERA_MODE.Focused && CAMERA_CONFIG.focusObjectType === CAMERA_FOCUS_OBJECT_TYPE.Monitor) {
      this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].setScreenInactive();
    }

    this._cursor.onFullScreenVideoStop();
    this.events.post('onHideGame')
  }

  _onTableMoving() {
    this._onObjectsMoving();
    this._disableFocusObjects();
  }

  _onTableMovingPercent() {
  }

  _onTableStop() {
    this._onObjectsStopMoving();
    this._enableFocusObjects();
  }

  _disableAllObjects() {
    for (let key in this._roomActiveObject) {
      ROOM_OBJECT_ENABLED_CONFIG[key] = false;
      const roomObject = this._roomActiveObject[key];
      roomObject.disableDebugMenu();
    }
  }

  _enableAllObjects() {
    for (let key in this._roomActiveObject) {
      ROOM_OBJECT_ENABLED_CONFIG[key] = true;
      const roomObject = this._roomActiveObject[key];
      roomObject.enableDebugMenu();
    }
  }

  _onObjectFocused(focusedObjectType) {
    this._roomActiveObject[focusedObjectType].showCloseFocusIcon();
  }

  _onRoomFocused() {
    this._orbitControls.customReset();
  }

  _disableBaseOnKeyboardFocus() {
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].setBaseInactive();
  }

  _enableBaseOnKeyboardFocus() {
    this._roomActiveObject[ROOM_OBJECT_TYPE.Keyboard].setBaseActive();
  }

  _disableScreensOnMonitorFocus() {
    const monitor = this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor];

    if (this._isGameActive) {
      monitor.setScreenActiveForGame();
    } else {
      monitor.setScreenInactive();
    }
  }

  _enableScreensOnMonitorFocus() {
    this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].setScreenInactiveForGame();
    this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor].setScreenActive();
  }

  _onKeyboardEscClick() {
    const monitor = this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor];

    if (this._isGameActive) {
      monitor.stopGame();
    }
  }

  _onKeyboardSpaceClick() {
    if (this._isGameActive) {
      this.events.post('onGameKeyPressed');
    }
  }

  _onKeyboardEnterClick() {
    if (this._isGameActive) {
      this.events.post('onGameKeyPressed');
    }
  }

  _onKeyboardMuteClick() {
    SOUNDS_CONFIG.enabled = !SOUNDS_CONFIG.enabled;
    this._roomDebug.updateSoundsEnabledController();
    this.onSoundsEnabledChanged();
  }

  _onKeyboardVolumeUpClick() {
    SOUNDS_CONFIG.volume += 0.1;

    if (SOUNDS_CONFIG.volume > 1) {
      SOUNDS_CONFIG.volume = 1;
    }

    this._roomDebug.updateSoundsVolumeController();
    this._onVolumeChanged();
  }

  _onKeyboardVolumeDownClick() {
    SOUNDS_CONFIG.volume -= 0.1;
    if (SOUNDS_CONFIG.volume < 0) {
      SOUNDS_CONFIG.volume = 0;
    }

    this._roomDebug.updateSoundsVolumeController();
    this._onVolumeChanged();
  }

  _onWindowFullyOpened() {
  }

  _onDebugHelpersChanged() {
    for (const key in this._roomActiveObject) {
      if (SOUNDS_CONFIG.debugHelpers) {
        this._roomActiveObject[key].showSoundHelpers();
      } else {
        this._roomActiveObject[key].hideSoundHelpers();
      }
    }
  }

  _onVolumeChanged(showIcon = true) {
    for (const key in this._roomActiveObject) {
      this._roomActiveObject[key].onVolumeChanged(SOUNDS_CONFIG.volume, showIcon);
    }

    this.events.post('onVolumeChanged');
  }

  onDebugSoundsEnabledChanged() {
    this.onSoundsEnabledChanged();
    this.events.post('onSoundsEnabledChanged');
  }

  _onMouseOnButtonClick(buttonType) {
    const monitor = this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor];

    monitor.onLeftKeyClick(buttonType);
  }

  _onStartSnowing() {
  }

  _onStopSnowing() {
  }

  _highlightAllActiveObjects() {
    this._roomDebug.onHighlightAllActiveObjects();

    if (this._isActiveObjectsHighlighted) {
      this._isActiveObjectsHighlighted = false;
      this._resetGlow();

      return;
    }

    this._isActiveObjectsHighlighted = true;
    const allMeshes = [];

    for (let key in this._roomActiveObject) {
      const roomObject = this._roomActiveObject[key];
      const meshes = roomObject.getMeshesForOutlinePreview();
      allMeshes.push(...meshes);
    }
  }

  _allObjectsInteraction() {
    const monitorOrLaptop = Math.random() > 0.5;
    const monitor = this._roomActiveObject[ROOM_OBJECT_TYPE.Monitor];

    if (monitorOrLaptop) {
      monitor.enableAllObjectsInteraction();
    } else {
      monitor.disableAllObjectsInteraction();
    }

    for (let key in this._roomActiveObject) {
      const roomObject = this._roomActiveObject[key];
      roomObject.onAllObjectsInteraction();
    }
  }

  _onLightPercentChange(lightPercent) {
    this._roomInactiveObject[ROOM_OBJECT_TYPE.MousePad].onLightPercentChange(lightPercent);
  }

  _onLightHalfOn() {
    this._lightsController.onLightHalfOn();
  }

  _onLightHalfOff() {
    this._lightsController.onLightHalfOff();
  }

  _onHelpersChange() {
    this._lightsController.onHelpersChange();
  }

  _onResetActiveObjects() {
    SOUNDS_CONFIG.volume = 0.5;
    this._roomDebug.updateSoundsVolumeController();
    this._onVolumeChanged(false);
  }

  _onIntroStop() {
    this.events.post('onIntroStop');
  }
}
