import * as THREE from "three";
import { MONITOR_BUTTONS_CONFIG, MONITOR_CONFIG } from "../../monitor/data/monitor-config";
import { MONITOR_SCREEN_BUTTONS } from "../../monitor/data/monitor-data";
import { CURSOR_CONFIG } from "./data/cursor-config";

export default class MonitorButtonsDebug extends THREE.Group {
  constructor(monitorScreen) {
    super();

    this._monitorScreen = monitorScreen;

    this._init();
  }

  _init() {
    this._initMonitorButtonsDebug();
  }

  _initMonitorButtonsDebug() {
    if (MONITOR_CONFIG.showDebugButtons) {
      MONITOR_SCREEN_BUTTONS.forEach((button) => {
        const { position, size } = MONITOR_BUTTONS_CONFIG.buttons[button].area;
        this._showDebugButtonsAreaForMonitor(position.x, position.y, size.x, size.y);
      });
    }
  }

  _showDebugButtonsAreaForMonitor(x, y, width, height) {
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const debugArea = new THREE.Mesh(geometry, material);
    this.add(debugArea);

    debugArea.scale.set(width, height, 1);
    debugArea.position.copy(this._monitorScreen.getWorldPosition(new THREE.Vector3()));

    debugArea.translateOnAxis(new THREE.Vector3(1, 0, 0), x);
    debugArea.translateOnAxis(new THREE.Vector3(0, 1, 0), y);
    debugArea.translateOnAxis(new THREE.Vector3(0, 0, 1), CURSOR_CONFIG.offsetZFromScreen);
  }
}
